const apartments_list = [
    {
        "id": 1,
        "name": "aparts_list.apart1.name",
        "destination": "Santiago",
        "location_mode": ["room"],
        "promotion": null,
        "availability": "aparts_list.apart1.availability",
        "img": ["1.webp", "2.webp", "3.webp", "4.webp", "5.webp", "6.webp", "7.webp", "8.webp", "9.webp", "10.mp4"],
	  "thumbnailOrder": [1, 4, 5, 7, 10],
        "rooms": [
            {
                "name": "aparts_list.apart1.room1.name",
                "price": 430,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart1.room1.availability",
                "description": "aparts_list.apart1.room1.description",
                "keywords": "aparts_list.apart1.room1.keywords",
                "img": ["1.webp", "2.mp4"]
            },
            {
                "name": "aparts_list.apart1.room2.name",
                "price": 430,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart1.room2.availability",
                "description": "aparts_list.apart1.room2.description",
                "keywords": "aparts_list.apart1.room2.keywords",
                "img": ["1.webp", "2.mp4"]
            },
            {
                "name": "aparts_list.apart1.room3.name",
                "price": 430,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart1.room3.availability",
                "description": "aparts_list.apart1.room3.description",
                "keywords": "aparts_list.apart1.room3.keywords",
                "img": ["1.webp", "2.mp4"]
            },
            {
                "name": "aparts_list.apart1.room4.name",
                "price": 420,
                "booked": false,
                "minimum": 5,
                "availability": "aparts_list.apart1.room4.availability",
                "description": "aparts_list.apart1.room4.description",
                "keywords": "aparts_list.apart1.room4.keywords",
                "img": ["1.webp", "2.mp4"]
            },
            {
                "name": "aparts_list.apart1.room5.name",
                "price": 420,
                "booked": false,
                "minimum": 5,
                "availability": "aparts_list.apart1.room5.availability",
                "description": "aparts_list.apart1.room5.description",
                "keywords": "aparts_list.apart1.room5.keywords",
                "img": ["1.webp", "2.mp4"]
            }
        ],
        "keywords": "aparts_list.apart1.keywords",
        "equipment": [
            "kitchen",
            "wifi",
            "washing",
            "tv",
            "towels",
            "furnished"
        ],
        "description": "aparts_list.apart1.description",
        "address": "33°26'16.3\"S 70°39'59.6\"W",
        "src": "/assets/apartments/santo_domingo_1"
    },
    {
        "id": 2,
        "name": "aparts_list.apart8.name",
        "destination": "Santiago",
        "location_mode": ["room"],
        "promotion": null,
        "availability": "aparts_list.apart8.availability",
        "img": ["1.jpg", "2.jpg", "3.mp4", "4.jpg", "5.jpg"],
        "thumbnailOrder": [1, 2, 3, 4, 5],
        "rooms": [
            {
                "name": "aparts_list.apart8.room1.name",
                "price": 445,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart8.room1.availability",
                "description": "aparts_list.apart8.room1.description",
                "keywords": "aparts_list.apart8.room1.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.mp4"]
            },
            {
                "name": "aparts_list.apart8.room2.name",
                "price": 425,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart8.room2.availability",
                "description": "aparts_list.apart8.room2.description",
                "keywords": "aparts_list.apart8.room2.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.mp4"]
            },
            {
                "name": "aparts_list.apart8.room3.name",
                "price": 425,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart8.room3.availability",
                "description": "aparts_list.apart8.room3.description",
                "keywords": "aparts_list.apart8.room3.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.mp4"]
            },
            {
                "name": "aparts_list.apart8.room4.name",
                "price": 445,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart8.room4.availability",
                "description": "aparts_list.apart8.room4.description",
                "keywords": "aparts_list.apart8.room4.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.mp4"]
            },
            {
                "name": "aparts_list.apart8.room5.name",
                "price": 445,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart8.room5.availability",
                "description": "aparts_list.apart8.room5.description",
                "keywords": "aparts_list.apart8.room5.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.mp4"]
            },
            {
                "name": "aparts_list.apart8.room6.name",
                "price": 415,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart8.room6.availability",
                "description": "aparts_list.apart8.room6.description",
                "keywords": "aparts_list.apart8.room6.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.mp4"]
            }
        ],
        "keywords": "aparts_list.apart8.keywords",
        "equipment": [
            "kitchen",
            "wifi",
            "washing",
            "tv",
            "towels",
            "furnished"
        ],
        "description": "aparts_list.apart8.description",
        "address": "33°26'16.3\"S 70°39'59.6\"W",
        "src": "/assets/apartments/santo_domingo_2"
    },
    {
        "id": 3,
        "name": "aparts_list.apart2.name",
        "destination": "Santiago",
        "location_mode": ["room"],
        "promotion": null,
        "availability": "aparts_list.apart2.availability",
        "img": ["1.jpeg", "2.jpeg", "3.jpeg", "4.jpeg", "5.jpeg", "6.jpeg", "7.jpeg", "8.jpeg", "9.jpeg", "10.jpeg", "11.jpeg", "12.jpeg"],
        "thumbnailOrder": [1, 2, 4, 5, 10],
        "rooms": [
            {
                "name": "aparts_list.apart2.room1.name",
                "price": 360,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart2.room1.availability",
                "description": "aparts_list.apart2.room1.description",
                "keywords": "aparts_list.apart2.room1.keywords",
                "img": ["1.jpeg"]
            },
            {
                "name": "aparts_list.apart2.room2.name",
                "price": 375,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart2.room2.availability",
                "description": "aparts_list.apart2.room2.description",
                "keywords": "aparts_list.apart2.room2.keywords",
                "img": ["1.jpeg", "2.png", "3.jpeg"]
            },
            {
                "name": "aparts_list.apart2.room3.name",
                "price": 375,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart2.room3.availability",
                "description": "aparts_list.apart2.room3.description",
                "keywords": "aparts_list.apart2.room3.keywords",
                "img": ["1.jpeg", "2.jpeg", "3.jpeg"]
            },
            {
                "name": "aparts_list.apart2.room4.name",
                "price": 375,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart2.room4.availability",
                "description": "aparts_list.apart2.room4.description",
                "keywords": "aparts_list.apart2.room4.keywords",
                "img": ["1.jpeg", "2.jpeg"]
            },
            {
                "name": "aparts_list.apart2.room5.name",
                "price": 375,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart2.room5.availability",
                "description": "aparts_list.apart2.room5.description",
                "keywords": "aparts_list.apart2.room5.keywords",
                "img": ["1.jpeg", "2.jpeg", "3.jpeg"]
            },
            {
                "name": "aparts_list.apart2.room6.name",
                "price": 360,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart2.room6.availability",
                "description": "aparts_list.apart2.room6.description",
                "keywords": "aparts_list.apart2.room6.keywords",
                "img": ["1.jpeg"]
            },
            {
                "name": "aparts_list.apart2.room7.name",
                "price": 375,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart2.room7.availability",
                "description": "aparts_list.apart2.room7.description",
                "keywords": "aparts_list.apart2.room7.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg"]
            },
            {
                "name": "aparts_list.apart2.room8.name",
                "price": 375,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart2.room8.availability",
                "description": "aparts_list.apart2.room8.description",
                "keywords": "aparts_list.apart2.room8.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg"]
            },
            {
                "name": "aparts_list.apart2.room9.name",
                "price": 415,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart2.room9.availability",
                "description": "aparts_list.apart2.room9.description",
                "keywords": "aparts_list.apart2.room9.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.mp4"]
            },
            {
                "name": "aparts_list.apart2.room10.name",
                "price": 450,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart2.room10.availability",
                "description": "aparts_list.apart2.room10.description",
                "keywords": "aparts_list.apart2.room10.keywords",
                "img": ["1.jpeg", "2.jpeg", "3.jpeg", "4.jpeg"]
            }
        ],
        "keywords": "aparts_list.apart2.keywords",
        "equipment": [
            "kitchen",
            "rooftop",
            "wifi",
            "washing",
            "tv",
            "towels",
            "furnished",
            "garden"
        ],
        "description": "aparts_list.apart2.description",
        "address": "33°26'25.1\"S 70°37'05.1\"W",
        "src": "/assets/apartments/providencia"
    },
    {
        "id": 4,
        "name": "aparts_list.apart3.name",
        "destination": "Valparaíso",
        "location_mode": ["room", "apartment"],
        "promotion": 50,
        "keywordsApart": "aparts_list.apart3.keywords-apart",
        "availability": "aparts_list.apart3.availability",
        "img": ["1.webp", "2.jpeg", "3.jpeg", "4.webp", "5.jpeg", "6.jpeg", "7.webp", "8.jpeg", "9.mp4", "9.webp", "10.jpeg", "11.webp", "12.webp", "13.jpeg", "14.jpeg", "15.webp", "16.jpeg", "17.jpeg"],
        "thumbnailOrder": [1, 2, 4, 9, 10],
        "rooms": [
            {
                "name": "aparts_list.apart3.room1.name",
                "price": 475,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart3.room1.availability",
                "description": "aparts_list.apart3.room1.description",
                "keywords": "aparts_list.apart3.room1.keywords",
                "img": ["1.webp", "2.webp", "3.jpeg"]
            },
            {
                "name": "aparts_list.apart3.room2.name",
                "price": 425,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart3.room2.availability",
                "description": "aparts_list.apart3.room2.description",
                "keywords": "aparts_list.apart3.room2.keywords",
                "img": ["1.jpg", "2.webp", "3.webp"]
            },
            {
                "name": "aparts_list.apart3.room3.name",
                "price": 375,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart3.room3.availability",
                "description": "aparts_list.apart3.room3.description",
                "keywords": "aparts_list.apart3.room3.keywords",
                "img": ["1.webp", "2.webp", "3.webp"]
            },
            {
                "name": "aparts_list.apart3.room4.name",
                "price": 375,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart3.room4.availability",
                "description": "aparts_list.apart3.room4.description",
                "keywords": "aparts_list.apart3.room4.keywords",
                "img": ["1.webp", "2.webp"]
            }
        ],
        "keywords": "aparts_list.apart3.keywords",
        "equipment": [
            "kitchen",
            "rooftop",
            "wifi",
            "washing",
            "laundry",
            "tv",
            "keeper",
            "pool_ext",
            "fitness",
            "furnished",
            "sea_view"
        ],
        "description": "aparts_list.apart3.description",
        "address": "Nueva Libertad 1640, Viña del Mar, Chile",
        "src": "/assets/apartments/marina"
    },
    {
        "id": 5,
        "name": "aparts_list.apart4.name",
        "destination": "Valparaíso",
        "location_mode": ["apartment"],
        "promotion": 50,
        "availability": "aparts_list.apart4.availability",
        "img": ["1.jpeg", "2.jpeg", "3.jpeg", "4.webp", "5.jpeg", "6.jpeg", "7.jpeg", "8.jpeg", "9.webp", "10.jpeg", "11.jpeg", "12.webp", "13.webp", "14.webp", "15.jpeg", "16.jpeg", "17.jpeg", "18.mp4", "19.mp4", "20.mp4", "21.mp4"],
        "thumbnailOrder": [1, 8, 10, 16, 18],
        "rooms": [
            {
                "name": "aparts_list.apart4.room1.name",
                "price": 575,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart4.room1.availability",
                "description": "aparts_list.apart4.room1.description",
                "keywords": "aparts_list.apart4.room1.keywords",
                "img": ["1.jpeg", "2.jpeg", "3.webp", "4.webp", "5.webp"]
            },
            {
                "name": "aparts_list.apart4.room2.name",
                "price": 475,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart4.room2.availability",
                "description": "aparts_list.apart4.room2.description",
                "keywords": "aparts_list.apart4.room2.keywords",
                "img": ["1.webp", "2.jpeg"]
            }
        ],
        "keywords": "aparts_list.apart4.keywords",
        "equipment": [
            "kitchen",
            "washing",
            "rooftop",
            "wifi",
            "laundry",
            "tv",
            "keeper",
            "pool_int",
            "pool_ext",
            "towels",
            "fitness",
            "furnished",
            "sea_view",
            "tennis",
            "squash"
        ],
        "description": "aparts_list.apart4.description",
        "address": "Avenida Corraceros 50, Viña del Mar, Chile",
        "src": "/assets/apartments/adriatico"
    },
    {
        "id": 6,
        "name": "aparts_list.apart5.name",
        "destination": "Valparaíso",
        "location_mode": ["apartment", "room"],
        "promotion": 75,
        "availability": "aparts_list.apart5.availability",
        "img": ["1.webp", "2.webp", "3.jpeg", "4.webp", "5.jpg", "6.webp", "7.jpg", "8.jpg", "9.mp4", "10.jpg", "11.jpg", "12.jpg", "13.webp", "14.mp4", "15.jpg", "16.webp", "17.jpg", "18.jpg", "19.jpeg", "20.jpg", "21.jpg", "22.jpeg", "23.jpeg"],
        "thumbnailOrder": [1, 5, 9, 10, 12],
        "rooms": [
            {
                "name": "aparts_list.apart5.room1.name",
                "price": 475,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart5.room1.availability",
                "description": "aparts_list.apart5.room1.description",
                "keywords": "aparts_list.apart5.room1.keywords",
                "img": ["1.webp", "2.webp"]
            },
            {
                "name": "aparts_list.apart5.room2.name",
                "price": 425,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart5.room2.availability",
                "description": "aparts_list.apart5.room2.description",
                "keywords": "aparts_list.apart5.room2.keywords",
                "img": ["1.webp", "2.webp"]
            },
            {
                "name": "aparts_list.apart5.room3.name",
                "price": 375,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart5.room3.availability",
                "description": "aparts_list.apart5.room3.description",
                "keywords": "aparts_list.apart5.room3.keywords",
                "img": ["1.webp", "2.webp"]
            }
        ],
        "keywords": "aparts_list.apart5.keywords",
        "equipment": [
            "kitchen",
            "furnished",
            "fitness",
            "towels",
            "pool_int",
            "pool_ext",
            "rooftop",
            "wifi",
            "washing",
            "laundry",
            "tv",
            "keeper",
            "sea_view",
            "ping_pong",
            "babyfoot"
        ],
        "description": "aparts_list.apart5.description",
        "address": "Avenida Corraceros 50, Viña del Mar, Chile",
        "src": "/assets/apartments/libertad"
    },
    {
        "id": 7,
        "name": "aparts_list.apart6.name",
        "destination": "Valparaíso",
        "location_mode": ["room", "apartment"],
        "promotion": null,
        "keywordsApart": "aparts_list.apart6.keywords-apart",
        "availability": "aparts_list.apart6.availability",
        "img": ["1.jpg", "2.mp4", "3.jpg", "4.jpg"],
        "thumbnailOrder": [1, 2, 3, 4],
        "rooms": [
            {
                "name": "aparts_list.apart6.room1.name",
                "price": 375,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart6.room1.availability",
                "description": "aparts_list.apart6.room1.description",
                "keywords": "aparts_list.apart6.room1.keywords",
                "img": ["1.jpg", "2.jpg", "3.mp4"]
            },
            {
                "name": "aparts_list.apart6.room2.name",
                "price": 375,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart6.room2.availability",
                "description": "aparts_list.apart6.room2.description",
                "keywords": "aparts_list.apart6.room2.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.mp4"]
            },
            {
                "name": "aparts_list.apart6.room3.name",
                "price": 300,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart6.room3.availability",
                "description": "aparts_list.apart6.room3.description",
                "keywords": "aparts_list.apart6.room3.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.mp4", "5.mp4"]
            },
            {
                "name": "aparts_list.apart6.room4.name",
                "price": 325,
                "booked": false,
                "minimum": 5,
                "availability": "aparts_list.apart6.room4.availability",
                "description": "aparts_list.apart6.room4.description",
                "keywords": "aparts_list.apart6.room4.keywords",
                "img": ["1.jpg", "2.mp4"]
            }
        ],
        "keywords": "aparts_list.apart6.keywords",
        "equipment": [
            "kitchen",
            "rooftop",
            "wifi",
            "washing",
            "tv",
            "keeper",
            "furnished",
            "sea_view",
            "towels"
        ],
        "description": "aparts_list.apart6.description",
        "address": "1 Poniente 1199, Viña del Mar, Chile",
        "src": "/assets/apartments/poniente"
    },
    {
        "id": 8,
        "name": "aparts_list.apart7.name",
        "destination": "Valparaíso",
        "location_mode": ["apartment"],
        "promotion": 50,
        "availability": "aparts_list.apart7.availability",
        "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg", "12.jpg", "13.jpg", "14.jpg", "15.jpg", "16.jpg", "17.jpg", "18.jpg", "19.jpg", "20.jpg", "21.jpg", "22.jpg", "23.jpg", "24.jpg", "25.jpg", "26.jpg", "27.jpg", "28.jpg", "29.mp4", "30.mp4", "31.jpg", "32.jpg"],
        "thumbnailOrder": [1, 4, 19, 27, 29],
        "rooms": [
            {
                "name": "aparts_list.apart7.room1.name",
                "price": 500,
                "booked": false,
                "minimum": 5,
                "availability": "aparts_list.apart7.room1.availability",
                "description": "aparts_list.apart7.room1.description",
                "keywords": "aparts_list.apart7.room1.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg"]
            },
            {
                "name": "aparts_list.apart7.room2.name",
                "price": 425,
                "booked": false,
                "minimum": 5,
                "availability": "aparts_list.apart7.room2.availability",
                "description": "aparts_list.apart7.room2.description",
                "keywords": "aparts_list.apart7.room2.keywords",
                "img": ["1.jpg", "2.jpg"]
            },
            {
                "name": "aparts_list.apart7.room3.name",
                "price": 400,
                "booked": false,
                "minimum": 5,
                "availability": "aparts_list.apart7.room3.availability",
                "description": "aparts_list.apart7.room3.description",
                "keywords": "aparts_list.apart7.room3.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg",  "4.jpg"]
            },
            {
                "name": "aparts_list.apart7.room4.name",
                "price": 325,
                "booked": false,
                "minimum": 5,
                "availability": "aparts_list.apart7.room4.availability",
                "description": "aparts_list.apart7.room4.description",
                "keywords": "aparts_list.apart7.room4.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg"]
            }
        ],
        "keywords": "aparts_list.apart7.keywords",
        "equipment": [
            "kitchen",
            "towels",
            "rooftop",
            "wifi",
            "washing",
            "laundry",
            "tv",
            "keeper",
            "pool_ext",
            "fitness",
            "furnished",
            "sea_view",
            "sauna",
            "ping_pong",
            "babyfoot"
        ],
        "description": "aparts_list.apart7.description",
        "address": "Avenida Jorge Montt 1540, Viña del Mar, Chile",
        "src": "/assets/apartments/pacifico"
    },
    {
        "id": 9,
        "name": "aparts_list.apart9.name",
        "destination": "Valparaíso",
        "location_mode": ["room"],
        "promotion": null,
        "availability": "aparts_list.apart9.availability",
        "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg", "11.jpg", "12.mp4"],
        "thumbnailOrder": [1, 2, 5, 9, 12],
        "rooms": [
            {
                "name": "aparts_list.apart9.room1.name",
                "price": 380,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart9.room1.availability",
                "description": "aparts_list.apart9.room1.description",
                "keywords": "aparts_list.apart9.room1.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.mp4"]
            },
            {
                "name": "aparts_list.apart9.room2.name",
                "price": 360,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart9.room2.availability",
                "description": "aparts_list.apart9.room2.description",
                "keywords": "aparts_list.apart9.room2.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.mp4"]
            },
            {
                "name": "aparts_list.apart9.room3.name",
                "price": 360,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart9.room3.availability",
                "description": "aparts_list.apart9.room3.description",
                "keywords": "aparts_list.apart9.room3.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.mp4"]
            },
            {
                "name": "aparts_list.apart9.room4.name",
                "price": 360,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart9.room4.availability",
                "description": "aparts_list.apart9.room4.description",
                "keywords": "aparts_list.apart9.room4.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.mp4"]
            },
            {
                "name": "aparts_list.apart9.room5.name",
                "price": 340,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart9.room5.availability",
                "description": "aparts_list.apart9.room5.description",
                "keywords": "aparts_list.apart9.room5.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.mp4", "7.mp4"]
            },
            {
                "name": "aparts_list.apart9.room6.name",
                "price": 345,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart9.room6.availability",
                "description": "aparts_list.apart9.room6.description",
                "keywords": "aparts_list.apart9.room6.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.mp4", "9.mp4"]
            },
            {
                "name": "aparts_list.apart9.room7.name",
                "price": 335,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart9.room7.availability",
                "description": "aparts_list.apart9.room7.description",
                "keywords": "aparts_list.apart9.room7.keywords",
                "img": ["1.jpg", "2.jpeg", "3.jpeg", "4.jpeg", "5.jpg", "6.mp4"]
            },
            {
                "name": "aparts_list.apart9.room8.name",
                "price": 320,
                "booked": true,
                "minimum": 5,
                "availability": "aparts_list.apart9.room8.availability",
                "description": "aparts_list.apart9.room8.description",
                "keywords": "aparts_list.apart9.room8.keywords",
                "img": ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.mp4"]
            }
        ],
        "keywords": "aparts_list.apart9.keywords",
        "equipment": [
            "kitchen",
            "rooftop",
            "wifi",
            "washing",
            "tv",
            "towels",
            "furnished",
            "garden"
        ],
        "description": "aparts_list.apart9.description",
        "address": "33°02'39.8\"S 71°37'45.8\"W",
        "src": "/assets/apartments/alegre"
    }
]


export default apartments_list